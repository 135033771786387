.rwd-table {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
  }
  @media (max-width: $screen-md-min) {
  font-size: 13px;
  }
  @media (max-width: $screen-sm-min) {
    border:0px;
    font-size: 12px;
  }
  tbody{
    tr{
      @media (max-width: $screen-sm-min) {
        display: block;
        margin-bottom: 2em;
        margin-top: 1em;
        box-shadow: 0 2px 1px 0 rgba(0,0,0,0.306), 0 1px 8px 0 rgba(0,0,0,.12);
        td{
          border-color: whitesmoke !important;
          background: white;
        }
      }
    }
  }
  td{
    display: block;

    &:first-child {
      padding-top: .5em;
    }
    &:last-child {
      padding-bottom: .5em;
    }


      &[data-th]:before {
        content: attr(data-th);
        font-weight: bold;
        width:8.3em; // magic number :( adjust according to your own content
        display: inline-block;
        @media (min-width: $screen-sm-min) {
          display: none;
        }
      }

  }

  th, td {
    text-align: left;

    @media (min-width: $screen-sm-min) {
      display: table-cell;
      padding: .25em .5em;
    }

  }
}
