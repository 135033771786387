
// Fonts
@import "node_modules/roboto-fontface/css/roboto/sass/roboto-fontface.scss";

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Ionicons
@import "node_modules/ionicons/dist/scss/ionicons.scss";

//Font awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

//Material icons
@import "node_modules/material-design-iconic-font/scss/material-design-iconic-font.scss";

//Toastr
@import "node_modules/toastr/toastr.scss";

//RWD Table
@import "rwd-table";

//FullCalendar Material
@import "fullcalendar-material";

//Datatables
@import "../../../node_modules/datatables.net-bs/css/dataTables.bootstrap.css";
//@import "../css/datatable-scroller.css";

//jQueryUi
@import "../../../node_modules/jquery-ui-bundle/jquery-ui.min.css";

//Fullcalendar
@import "../../../node_modules/fullcalendar/dist/fullcalendar.css";

//Select2
@import "node_modules/select2/src/scss/core.scss";

//X-EDITABLE
@import "../css/xeditable.css";

//Select2
@import "node_modules/sweetalert/dev/sweetalert.scss";

//magnific-popup
@import "node_modules/magnific-popup/src/css/main.scss";

//Datetimepicker
@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";

//Qtip2
@import "../../../node_modules/qtip2/dist/basic/jquery.qtip.min.css";
@import "../../../node_modules/qtip2/dist/jquery.qtip.min.css";

//jQuery AutoComplete
@import "../../../bower_components/jquery-auto-complete/jquery.auto-complete.css";

//Color picker
@import "../../../node_modules/spectrum-colorpicker/spectrum.css";

//IntroJs
@import "../../../node_modules/intro.js/introjs.css";
@import "../../../node_modules/intro.js/themes/introjs-flattener.css";

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 20%)) {
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }
}

@include scrollbars(.5em, $brand-primary);
/***********************************************
VUE CLOAKING UTILITIES
************************************************/
html body{
  font-family: 'Roboto','Helvetica','sans-serif';
  font-weight: 300;
}
[v-cloak] .v-cloak--block {
  display: block;
}
[v-cloak] .v-cloak--inline {
  display: inline;
}
[v-cloak] .v-cloak--inlineBlock {
  display: inline-block;
}
[v-cloak] .v-cloak--hidden {
  display: none;
}
[v-cloak] .v-cloak--invisible {
  visibility: hidden;
}
.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
  display: none;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}

.datatable-search {
  * {
    width:100% !important;
    margin: 0 !important;
  }
}
//MENU
body {
  &.dashboard {
    .menu.menu-dashboard {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.expediente {
    .menu.menu-expediente {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.dashboard {
    .menu.menu-dashboard {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.usuario {
    .menu.menu-usuario {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.contacto {
    .menu.menu-contacto {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.cliente {
    .menu.menu-cliente {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.propiedad {
    .menu.menu-propiedad {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.sistema {
    .menu.menu-sistema {
      a {
        border-left:3px solid white;
      }
    }
  }
  &.texto_predefinido {
    .menu.menu-texto_predefinido {
      a {
        border-left:3px solid white;
      }
    }
  }
}

.table {
  tr.clickable {
    cursor:pointer;
    &:hover {
      background: #f5f5f5;
    }
  }
  &.table-mini{
    tbody{
      td {
        padding:4px;
      }
    }
  }
}
.panel {
  border-radius: 0px;
  .panel-heading {
    border-radius: 0px;
    font-size: 18px;
    font-weight: 100;
    b,strong {
      font-weight: 400;
    }
  }
  .panel-default {
    border-color: #e0e0e0;
    .panel-heading {
      border-color: #e0e0e0;
    }
  }
  .panel-primary-o {
    border-color: $brand-primary;
    .panel-heading {
      background-color:  #f5f5f5;
      border-bottom:1px solid $brand-primary;
      color: $brand-primary;
    }
  }
}
.bordered-data {
  p {
    padding-bottom:5px;
    border-bottom: 1px solid #e0e0e0;
  }
  p > a {
    border-bottom: 1px dashed $brand-primary;
  }
}
.form-control {
  &.form-control-big {
    font-size:18px;
    height: 50px;
  }
}
.dataTables_wrapper {
  .dataTables_processing {
    color:$brand-primary !important;
    z-index: 99999999999999 !important;
    background: #f5f5f5;
  }
}
.dataTables_scrollHeadInner{
  background-color: whitesmoke;
  border-bottom: 1px solid silver;
}
html body .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #fafafa;
  color:black;
}

.img-slide {
  padding:5px;
  border:1px solid silver;
  min-width:150px;
  margin-bottom:10px;
  display:inline-block;
  height:150px;
  &:hover {
    box-shadow: 0px 0px 0px 1px black;
  }
}
.thumbnail {
  height: 150px;
  overflow:hidden;
  text-align: center;
  border-radius: 0px;
  img {
    height:100%;
    display: inline-block;
    vertical-align: middle;
  }
}

#buscador {
  input{
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:focus{      
      font-size: 16px;
    }
  }
  &:hover {
    .resultados_busqueda {
      opacity: 1;
    }
  }
  .resultados_busqueda {
    max-height: 200px;
    overflow-y: scroll;
    opacity: 0.5;
    .resultado_busqueda {
      display: inline-block;
      width: 100%;
      background: white;
      padding: 8px 10px;
      border: 1px solid lightgrey;
      color: black;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:hover {
        border-color: $brand-primary;
      }
    }
  }
}

.resultado_busqueda {
  display: inline-block;
  width: 100%;
  background: white;
  padding: 8px 10px;
  border: 1px solid lightgrey;
  color: black;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: $brand-primary;
    color:black;
  }
}

#previsualizador_content {
  padding: 20px;
  border: 1px solid silver;
  box-shadow: 0px 0px 1px 2px whitesmoke;
}
.datepicker > div {
  display: block ;
}

.label {
  border-radius: 0px;
  &.selected {
    border: 1px solid white;
    outline: 1px solid #765379;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  * {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }
}

.badge {
  &.badge-info{
    background: lightgrey;
    margin-left:5px;
  }
}
.fadepager{
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 60px;
  opacity: 0.5;
  cursor: pointer;
  &:hover{
    opacity: 1;
    font-size: 72px;
  }
}

.select2-selection--multiple .select2-search--inline .select2-search__field {
   width: auto !important;
}

.box {
  &.box-primary {
    &.box-dark {
      background: $brand-primary;
      .resultado_busqueda{
        background: lighten($brand-primary, 5%);
        * {
          color: whitesmoke;
        }
      }
    }
  }
}

.modal {
  .modal-dialog {
    &.modal-xl {
      width: 95%;
      margin-top:3%;
    }

    .close {
      font-size:32px;
    }

    .modal-title{
      font-weight: 100;
      font-size:24px;
    }
  }
  .modal-calendar {
    @media screen and (min-width: 1200px) {
      width: 1200px;
    }    
  }
}
.dad-fullscreenable{
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &.dad-fullscreen {
    position: fixed;
    top: 0px !important;
    z-index: 1030;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
.alert {
  &.alert-success-o {
    background: whitesmoke;
    border: 1px solid $brand-success;
    border-radius: 0px;
    color: black;
  }
  &.alert-warning-o {
    background: whitesmoke;
    border: 1px solid $brand-warning;
    border-radius: 0px;
    color: black;
  }
  &.alert-danger-o {
    background: whitesmoke;
    border: 1px solid $brand-danger;
    border-radius: 0px;
    color: black;
  }
}
.box{
  &.box-minimized{
    position: fixed;
    bottom:0;
    right:0;
    margin-bottom:0;
    width:400px;
  }
  &.box-primary {
    .box-header {
      background: $brand-primary;      
      * {
        color: whitesmoke;
      }
    }
    .box-footer {
      background: whitesmoke;
    }
  }
  &.box-primary-o {
    .box-header {
      border-color: $brand-primary;            
      * {
        color: $brand-primary;
      }
    }
    .box-footer {
      background: whitesmoke;
    }
  }
}
.text-medium {
  font-weight: 500;
}
.bg-warning {
  background-color: #fcf8e3;
}
.bg-success {
  background-color:#dff0d8;
}
// IntroJS Fix
.introjs-fixParent {
  position: inherit;
}