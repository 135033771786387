

.fc {
  .fc-center {
    h2 {
      text-transform: uppercase;
      font-size: 25px;
      margin-top: 2px;
    }
  }
  .fc-toolbar{
    &.fc-header-toolbar{
      background: darken($brand-primary,10%);
      color: white;
    }
  }
  .fc-selected{
    background: lighten($brand-primary, 40%);
    color: white;
  }
}
